import { TextField } from '@mui/material';
import { Field } from 'formik';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { TournamentRegistrationAdditionalInformationFields_tournamentRegistrationSetup$key } from './__generated__/TournamentRegistrationAdditionalInformationFields_tournamentRegistrationSetup.graphql';

type Props = {
    tournamentRegistrationSetuo: TournamentRegistrationAdditionalInformationFields_tournamentRegistrationSetup$key | null;
    fieldPrefix?: string;
};

const TournamentRegistrationAdditionalInformationFields: FC<Props> = ({
    tournamentRegistrationSetuo: tournamentRegistrationSetupRef,
    fieldPrefix,
}) => {
    const tournamentRegistrationSetuo = useFragment(
        graphql`
            fragment TournamentRegistrationAdditionalInformationFields_tournamentRegistrationSetup on TournamentRegistrationSetup {
                isWeightRequired
                isHeightRequired
                isAdditionalInformationRequired
                additionalInformationLabel
            }
        `,
        tournamentRegistrationSetupRef
    );

    return (
        <>
            {tournamentRegistrationSetuo?.isWeightRequired && (
                <Field
                    as={TextField}
                    size="small"
                    name={[fieldPrefix, 'weight'].filter(Boolean).join('.')}
                    label={<FormattedMessage defaultMessage="Weight" id="zCb8fX" />}
                    type="number"
                    inputProps={{
                        min: 0,
                        step: 0.1,
                    }}
                    InputProps={{
                        endAdornment: <FormattedMessage defaultMessage="KG" id="V1mFr3" />,
                    }}
                    required
                    fullWidth
                />
            )}
            {tournamentRegistrationSetuo?.isHeightRequired && (
                <Field
                    as={TextField}
                    size="small"
                    name={[fieldPrefix, 'height'].filter(Boolean).join('.')}
                    label={<FormattedMessage defaultMessage="Height" id="teLZyZ" />}
                    type="number"
                    inputProps={{
                        min: 0,
                        step: 0.1,
                    }}
                    InputProps={{
                        endAdornment: <FormattedMessage defaultMessage="CM" id="RfQJlg" />,
                    }}
                    required
                    fullWidth
                />
            )}
            {tournamentRegistrationSetuo?.isAdditionalInformationRequired && (
                <Field
                    as={TextField}
                    size="small"
                    name={[fieldPrefix, 'additionalInformation'].filter(Boolean).join('.')}
                    label={tournamentRegistrationSetuo?.additionalInformationLabel || ''}
                    required
                    fullWidth
                />
            )}
        </>
    );
};

export default TournamentRegistrationAdditionalInformationFields;
