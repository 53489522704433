/**
 * @generated SignedSource<<5d94b71b02b058210dff96d530c7fd3d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CategorySexEnum = "f" | "m" | "u" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TournamentRegistrationUserForm_tournament$data = {
  readonly categories: ReadonlyArray<{
    readonly ageFrom: number;
    readonly ageTo: number;
    readonly checkTournamentDate: boolean;
    readonly entityId: number;
    readonly isTeam: boolean;
    readonly name: string;
    readonly sex: CategorySexEnum;
    readonly " $fragmentSpreads": FragmentRefs<"filterUserCategories_category">;
  }>;
  readonly entityId: number;
  readonly registration: {
    readonly isAdditionalInformationRequired: boolean;
    readonly isHeightRequired: boolean;
    readonly isOpen: boolean;
    readonly isWeightRequired: boolean;
    readonly judgeTitles: ReadonlyArray<{
      readonly entityId: number;
      readonly name: string;
    }>;
    readonly officialTitles: ReadonlyArray<{
      readonly entityId: number;
      readonly name: string;
    }>;
    readonly " $fragmentSpreads": FragmentRefs<"TournamentRegistrationAdditionalInformationFields_tournamentRegistrationSetup">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"filterUserCategories_tournament">;
  readonly " $fragmentType": "TournamentRegistrationUserForm_tournament";
};
export type TournamentRegistrationUserForm_tournament$key = {
  readonly " $data"?: TournamentRegistrationUserForm_tournament$data;
  readonly " $fragmentSpreads": FragmentRefs<"TournamentRegistrationUserForm_tournament">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "checkTournamentDate",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ageFrom",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ageTo",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sex",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isTeam",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TournamentRegistrationUserForm_tournament",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "filterUserCategories_tournament",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "date",
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "TournamentRegistrationSetup",
      "kind": "LinkedField",
      "name": "registration",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isOpen",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TournamentRegistrationAdditionalInformationFields_tournamentRegistrationSetup"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TournamentOfficialTitle",
          "kind": "LinkedField",
          "name": "officialTitles",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TournamentJudgeTitle",
          "kind": "LinkedField",
          "name": "judgeTitles",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isWeightRequired",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isHeightRequired",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isAdditionalInformationRequired",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "isTeam",
          "value": false
        }
      ],
      "concreteType": "Category",
      "kind": "LinkedField",
      "name": "categories",
      "plural": true,
      "selections": [
        {
          "kind": "InlineDataFragmentSpread",
          "name": "filterUserCategories_category",
          "selections": [
            (v0/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "CategoryGrade",
              "kind": "LinkedField",
              "name": "grades",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Grade",
                  "kind": "LinkedField",
                  "name": "grade",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        (v0/*: any*/),
        (v1/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/),
        (v7/*: any*/)
      ],
      "storageKey": "categories(isTeam:false)"
    }
  ],
  "type": "Tournament",
  "abstractKey": null
};
})();

(node as any).hash = "d6b88bb91f287bf85fc14c8bb83caa2f";

export default node;
