/**
 * @generated SignedSource<<ea9c9293b6d572f1f9ec7a20da8a7d8e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type filterUserCategories_tournament$data = {
  readonly date: string;
  readonly " $fragmentType": "filterUserCategories_tournament";
};
export type filterUserCategories_tournament$key = {
  readonly " $data"?: filterUserCategories_tournament$data;
  readonly " $fragmentSpreads": FragmentRefs<"filterUserCategories_tournament">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "filterUserCategories_tournament"
};

(node as any).hash = "83b23955d70cd03065a563d352db4925";

export default node;
