import Search from '@mui/icons-material/Search';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import { Field, Form, Formik } from 'formik';
import { FC, Suspense, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { filterNullNodes } from '@ktech/relay';
import UserInfoCard from '@ktech/components/src/user/UserInfoCard';
import { useUserSearchQuery } from './hooks/useUserSearchQuery';

const LOAD_LIMIT = 20;

const UserSearchContainer: FC<{
    organizationEntityId?: number[] | number | null;
    ownerUserEntityId?: number | null;
    search: string;
    onSelect: (userEntityId: number) => void;
}> = ({ organizationEntityId, ownerUserEntityId, search, onSelect }) => {
    const { data, hasNext, loadNext, isLoadingNext } = useUserSearchQuery({
        search,
        organizationEntityId,
        ownerUserEntityId,
    });

    return (
        <Stack spacing={1} mt={2}>
            {!data?.users.totalResults && (
                <Alert severity="warning">
                    <FormattedMessage defaultMessage="No users found" id="XWcjJb" />
                </Alert>
            )}
            {data?.users.edges
                .filter(filterNullNodes)
                .map(({ cursor, node }) => (
                    <UserInfoCard
                        key={cursor}
                        user={node}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => onSelect(node.entityId)}
                    />
                ))}

            {hasNext && (
                <Button
                    variant="outlined"
                    disabled={isLoadingNext}
                    onClick={() => loadNext(LOAD_LIMIT)}
                >
                    <FormattedMessage defaultMessage="Load more" id="00LcfG" />
                </Button>
            )}
        </Stack>
    );
};

type Props = {
    organizationEntityId?: number[] | number | null;
    ownerUserEntityId?: number | null;
    onSelect: (userEntityId: number) => void;
};

const UserSearchSelect: FC<Props> = ({ organizationEntityId, ownerUserEntityId, onSelect }) => {
    const [search, setSearch] = useState('');

    return (
        <div>
            <Formik initialValues={{ search }} onSubmit={data => setSearch(data.search)}>
                <Form>
                    <Field
                        as={TextField}
                        fullWidth
                        name="search"
                        label={<FormattedMessage defaultMessage="Search" id="xmcVZ0" />}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton>
                                        <Search />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Form>
            </Formik>

            <Suspense>
                <UserSearchContainer
                    organizationEntityId={organizationEntityId}
                    ownerUserEntityId={ownerUserEntityId}
                    search={search}
                    onSelect={onSelect}
                />
            </Suspense>
        </div>
    );
};

export default UserSearchSelect;
