/**
 * @generated SignedSource<<b5941c6fd91aa052666082507f1d179e>>
 * @relayHash 8ce73e1a933ab9a5fab8ca37fd3970d4
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 8ce73e1a933ab9a5fab8ca37fd3970d4

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TournamentRegistrationSuccessQuery$variables = {
  tournamentEntityId: number;
  userEntityIds: ReadonlyArray<number>;
};
export type TournamentRegistrationSuccessQuery$data = {
  readonly users: {
    readonly edges: ReadonlyArray<{
      readonly cursor: string;
      readonly node: {
        readonly tournamentRegistration: {
          readonly " $fragmentSpreads": FragmentRefs<"TournamentRegistrationUserInfo_tournamentRegistration">;
        } | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"UserInfoCard_user">;
      } | null | undefined;
    }>;
  };
};
export type TournamentRegistrationSuccessQuery = {
  response: TournamentRegistrationSuccessQuery$data;
  variables: TournamentRegistrationSuccessQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tournamentEntityId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userEntityIds"
},
v2 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_in",
            "variableName": "userEntityIds"
          }
        ],
        "kind": "ObjectValue",
        "name": "entityId"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "tournamentEntityId",
    "variableName": "tournamentEntityId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Category",
  "kind": "LinkedField",
  "name": "category",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "title",
    "storageKey": null
  },
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TournamentRegistrationSuccessQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "UserConnection",
        "kind": "LinkedField",
        "name": "users",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "UserInfoCard_user"
                  },
                  {
                    "alias": null,
                    "args": (v4/*: any*/),
                    "concreteType": "TournamentRegistrationUser",
                    "kind": "LinkedField",
                    "name": "tournamentRegistration",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "TournamentRegistrationUserInfo_tournamentRegistration"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TournamentRegistrationSuccessQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "UserConnection",
        "kind": "LinkedField",
        "name": "users",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fullName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "birthDay",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "age",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "photo",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserData",
                    "kind": "LinkedField",
                    "name": "userData",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Grade",
                        "kind": "LinkedField",
                        "name": "grade",
                        "plural": false,
                        "selections": (v6/*: any*/),
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v4/*: any*/),
                    "concreteType": "TournamentRegistrationUser",
                    "kind": "LinkedField",
                    "name": "tournamentRegistration",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TournamentRegistrationParticipant",
                        "kind": "LinkedField",
                        "name": "participants",
                        "plural": true,
                        "selections": [
                          (v7/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TournamentRegistrationTeam",
                        "kind": "LinkedField",
                        "name": "teams",
                        "plural": true,
                        "selections": [
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TournamentRegistrationParticipant",
                            "kind": "LinkedField",
                            "name": "participants",
                            "plural": true,
                            "selections": [
                              (v8/*: any*/),
                              (v7/*: any*/),
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TournamentRegistrationJudge",
                        "kind": "LinkedField",
                        "name": "judges",
                        "plural": true,
                        "selections": (v9/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TournamentRegistrationOfficial",
                        "kind": "LinkedField",
                        "name": "officials",
                        "plural": true,
                        "selections": (v9/*: any*/),
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "8ce73e1a933ab9a5fab8ca37fd3970d4",
    "metadata": {},
    "name": "TournamentRegistrationSuccessQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "4c34f354b5ab7c24d76ee1fa1f389d6b";

export default node;
