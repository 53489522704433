/**
 * @generated SignedSource<<310bbc8566c6f3783910502a5bf54ddb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useUserSearchQuery_query$data = {
  readonly users: {
    readonly edges: ReadonlyArray<{
      readonly cursor: string;
      readonly node: {
        readonly entityId: number;
        readonly fullName: string;
        readonly organizations: ReadonlyArray<{
          readonly entityId: number;
          readonly name: string;
        }>;
        readonly " $fragmentSpreads": FragmentRefs<"UserInfoCard_user">;
      } | null | undefined;
    }>;
    readonly totalResults: number;
  };
  readonly " $fragmentType": "useUserSearchQuery_query";
};
export type useUserSearchQuery_query$key = {
  readonly " $data"?: useUserSearchQuery_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"useUserSearchQuery_query">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "users"
],
v1 = [
  {
    "kind": "Variable",
    "name": "_like",
    "variableName": "search"
  }
],
v2 = {
  "kind": "Variable",
  "name": "parentOrganizationEntityId",
  "variableName": "organizationEntityId"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "after"
    },
    {
      "kind": "RootArgument",
      "name": "entityId"
    },
    {
      "kind": "RootArgument",
      "name": "first"
    },
    {
      "kind": "RootArgument",
      "name": "organizationEntityId"
    },
    {
      "kind": "RootArgument",
      "name": "search"
    },
    {
      "kind": "RootArgument",
      "name": "userEntityId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./useUserSearchQueryRefetchQuery.graphql')
    }
  },
  "name": "useUserSearchQuery_query",
  "selections": [
    {
      "alias": "users",
      "args": [
        {
          "fields": [
            {
              "items": [
                {
                  "fields": [
                    {
                      "fields": (v1/*: any*/),
                      "kind": "ObjectValue",
                      "name": "firstName"
                    }
                  ],
                  "kind": "ObjectValue",
                  "name": "_or.0"
                },
                {
                  "fields": [
                    {
                      "fields": (v1/*: any*/),
                      "kind": "ObjectValue",
                      "name": "lastName"
                    }
                  ],
                  "kind": "ObjectValue",
                  "name": "_or.1"
                }
              ],
              "kind": "ListValue",
              "name": "_or"
            },
            {
              "kind": "Variable",
              "name": "entityId",
              "variableName": "entityId"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        },
        {
          "kind": "Variable",
          "name": "organizationEntityId",
          "variableName": "organizationEntityId"
        },
        (v2/*: any*/),
        {
          "kind": "Variable",
          "name": "userEntityId",
          "variableName": "userEntityId"
        }
      ],
      "concreteType": "UserConnection",
      "kind": "LinkedField",
      "name": "__useUserSearchQuery_users_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalResults",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "UserInfoCard_user"
                },
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fullName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    (v2/*: any*/)
                  ],
                  "concreteType": "Organization",
                  "kind": "LinkedField",
                  "name": "organizations",
                  "plural": true,
                  "selections": [
                    (v3/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "2906b1cd4a1e29c3eb03635614941289";

export default node;
