/**
 * @generated SignedSource<<7302c4c2420ae93d7d06a55349330c01>>
 * @relayHash b2916365c17dc4964f049cdbb82a97b4
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID b2916365c17dc4964f049cdbb82a97b4

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationTypeEnum = "ORGANIZATION" | "SCHOOL" | "%future added value";
export type SexEnum = "f" | "m" | "%future added value";
export type TournamentRegistrationUserFormQuery$variables = {
  parentOrganizationEntityId?: ReadonlyArray<number> | null | undefined;
  tournamentEntityId: number;
  userEntityId: number;
};
export type TournamentRegistrationUserFormQuery$data = {
  readonly user: {
    readonly organizations: ReadonlyArray<{
      readonly entityId: number;
      readonly name: string;
      readonly type: OrganizationTypeEnum;
    }>;
    readonly sex: SexEnum | null | undefined;
    readonly tournamentRegistration: {
      readonly " $fragmentSpreads": FragmentRefs<"TournamentRegistrationUserForm_tournamentRegistration">;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"filterUserCategories_user">;
  } | null | undefined;
};
export type TournamentRegistrationUserFormQuery = {
  response: TournamentRegistrationUserFormQuery$data;
  variables: TournamentRegistrationUserFormQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "parentOrganizationEntityId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tournamentEntityId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userEntityId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "entityId",
    "variableName": "userEntityId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "birthDay",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "age",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sex",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = [
  {
    "kind": "Variable",
    "name": "tournamentEntityId",
    "variableName": "tournamentEntityId"
  }
],
v9 = [
  {
    "kind": "Variable",
    "name": "parentOrganizationEntityId",
    "variableName": "parentOrganizationEntityId"
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TournamentRegistrationUserFormQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "kind": "InlineDataFragmentSpread",
            "name": "filterUserCategories_user",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserData",
                "kind": "LinkedField",
                "name": "userData",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Grade",
                    "kind": "LinkedField",
                    "name": "grade",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "args": null,
            "argumentDefinitions": []
          },
          (v6/*: any*/),
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "TournamentRegistrationUser",
            "kind": "LinkedField",
            "name": "tournamentRegistration",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "TournamentRegistrationUserForm_tournamentRegistration"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v9/*: any*/),
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organizations",
            "plural": true,
            "selections": [
              (v10/*: any*/),
              (v7/*: any*/),
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "TournamentRegistrationUserFormQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserData",
            "kind": "LinkedField",
            "name": "userData",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Grade",
                "kind": "LinkedField",
                "name": "grade",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              },
              (v12/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "TournamentRegistrationUser",
            "kind": "LinkedField",
            "name": "tournamentRegistration",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "additionalInformation",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserData",
                "kind": "LinkedField",
                "name": "userData",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "weight",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "height",
                    "storageKey": null
                  },
                  (v12/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TournamentRegistrationParticipant",
                "kind": "LinkedField",
                "name": "participants",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Category",
                    "kind": "LinkedField",
                    "name": "category",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v12/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TournamentRegistrationJudge",
                "kind": "LinkedField",
                "name": "judges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "judgeTitleEntityId",
                    "storageKey": null
                  },
                  (v13/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TournamentRegistrationOfficial",
                "kind": "LinkedField",
                "name": "officials",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "officialTitleEntityId",
                    "storageKey": null
                  },
                  (v13/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              },
              (v12/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v9/*: any*/),
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organizations",
            "plural": true,
            "selections": [
              (v10/*: any*/),
              (v7/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/)
            ],
            "storageKey": null
          },
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "b2916365c17dc4964f049cdbb82a97b4",
    "metadata": {},
    "name": "TournamentRegistrationUserFormQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "11ba8520f5893f072651a11ea14bb88b";

export default node;
