import { filterFalsy } from '@ktech/relay';
import LoadingButton from '@ktech/components/src/LoadingButton';
import { useNotifications } from '@ktech/components/src/Notifications';
import FormikSelect from '@ktech/components/src/FormikSelect';
import filterUserCategories from '@ktech/components/src/helper/filterUserCategories';
import Add from '@mui/icons-material/Add';
import Alert from '@mui/material/Alert';
import Clear from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

import { FieldArray, Form, Formik } from 'formik';
import { FC, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment, useLazyLoadQuery, useMutation } from 'react-relay';
import { TournamentRegistrationUserFormMutation } from './__generated__/TournamentRegistrationUserFormMutation.graphql';
import { TournamentRegistrationUserFormQuery } from './__generated__/TournamentRegistrationUserFormQuery.graphql';
import { TournamentRegistrationUserForm_tournament$key } from './__generated__/TournamentRegistrationUserForm_tournament.graphql';
import { TournamentRegistrationUserForm_tournamentRegistration$key } from './__generated__/TournamentRegistrationUserForm_tournamentRegistration.graphql';
import { AlertTitle, Stack } from '@mui/material';
import TournamentRegistrationAdditionalInformationFields from './TournamentRegistrationAdditionalInformationFields';

type Props = {
    tournament: TournamentRegistrationUserForm_tournament$key;
    userEntityId: number;
    parentOrganizationEntityId?: number | null;
    onRegistrationComplete: (userEntityId: number) => void;
};

const TournamentRegistrationUserForm: FC<Props> = ({
    userEntityId,
    parentOrganizationEntityId,
    tournament: tournamentRef,
    onRegistrationComplete,
}) => {
    const { show } = useNotifications();
    const tournament = useFragment(
        graphql`
            fragment TournamentRegistrationUserForm_tournament on Tournament {
                ...filterUserCategories_tournament
                entityId
                registration {
                    isOpen
                }
                categories(isTeam: false) {
                    ...filterUserCategories_category
                    entityId
                    name
                    checkTournamentDate
                    ageFrom
                    ageTo
                    sex
                    isTeam
                }
                registration {
                    ...TournamentRegistrationAdditionalInformationFields_tournamentRegistrationSetup
                    officialTitles {
                        entityId
                        name
                    }
                    judgeTitles {
                        entityId
                        name
                    }
                    isWeightRequired
                    isHeightRequired
                    isAdditionalInformationRequired
                }
            }
        `,
        tournamentRef
    );

    const query = useLazyLoadQuery<TournamentRegistrationUserFormQuery>(
        graphql`
            query TournamentRegistrationUserFormQuery(
                $userEntityId: Int!
                $parentOrganizationEntityId: [Int!]
                $tournamentEntityId: Int!
            ) {
                user(entityId: $userEntityId) {
                    ...filterUserCategories_user
                    sex
                    tournamentRegistration(tournamentEntityId: $tournamentEntityId) {
                        ...TournamentRegistrationUserForm_tournamentRegistration
                    }
                    organizations(parentOrganizationEntityId: $parentOrganizationEntityId) {
                        entityId
                        name
                        type
                    }
                }
            }
        `,
        {
            userEntityId,
            parentOrganizationEntityId: parentOrganizationEntityId
                ? [parentOrganizationEntityId]
                : null,
            tournamentEntityId: tournament.entityId,
        },
        { fetchPolicy: 'store-and-network' }
    );

    const { isWeightRequired, isHeightRequired, isAdditionalInformationRequired } =
        tournament.registration || {};
    const userOrganizations = query.user?.organizations || [];
    const organizationEntityIds = userOrganizations.map(({ entityId }) => entityId);
    const firstOrganizationEntityId: number | undefined = organizationEntityIds[0];

    const hasOrganizationTypeOrganization = userOrganizations.some(
        ({ type }) => type === 'ORGANIZATION'
    );

    const tournamentRegistration =
        useFragment<TournamentRegistrationUserForm_tournamentRegistration$key>(
            graphql`
                fragment TournamentRegistrationUserForm_tournamentRegistration on TournamentRegistrationUser {
                    entityId
                    additionalInformation
                    userData {
                        weight
                        height
                    }
                    participants {
                        category {
                            entityId
                        }
                    }
                    judges {
                        judgeTitleEntityId
                        title
                    }
                    officials {
                        officialTitleEntityId
                        title
                    }
                }
            `,
            query.user?.tournamentRegistration || null
        );

    const [tournamentRegistrationRegisterUser, inFlight] =
        useMutation<TournamentRegistrationUserFormMutation>(graphql`
            mutation TournamentRegistrationUserFormMutation(
                $input: TournamentRegistrationRegisterUserInput!
            ) {
                tournamentRegistrationRegisterUser(input: $input) {
                    deletedNodeId @deleteRecord
                    tournamentRegistrationUser {
                        ...TournamentRegistrationUserForm_tournamentRegistration
                    }
                }
            }
        `);

    const user = query.user;

    const registrationParticipantCategoryIds = tournamentRegistration?.participants
        .filter(filterFalsy)
        .map(p => p.category.entityId)
        .filter(Boolean);

    const registrationIsClosed = !tournament.registration?.isOpen;
    const hasBeenRegistered = !!tournamentRegistration;
    const hasRegistrationOfficial = !!tournament.registration?.officialTitles.length;
    const hasRegistrationJudge = !!tournament.registration?.officialTitles.length;
    const registrationOfficialTitleId = tournamentRegistration?.officials[0]?.officialTitleEntityId;
    const registrationJudgeTitleId = tournamentRegistration?.judges[0]?.judgeTitleEntityId;

    const initialValues: {
        organizationEntityId: number | '';
        category: (number | '')[];
        officialTitleEntityId: number | '';
        judgeTitleEntityId: number | '';
        weight: number | '';
        height: number | '';
        additionalInformation: string;
    } = {
        organizationEntityId: firstOrganizationEntityId ?? '',
        category: registrationParticipantCategoryIds?.length
            ? registrationParticipantCategoryIds
            : [''],
        officialTitleEntityId: registrationOfficialTitleId ?? '',
        judgeTitleEntityId: registrationJudgeTitleId ?? '',
        weight: tournamentRegistration?.userData.weight ?? '',
        height: tournamentRegistration?.userData.height ?? '',
        additionalInformation: tournamentRegistration?.additionalInformation ?? '',
    };

    const onSubmit = (data: typeof initialValues) => {
        tournamentRegistrationRegisterUser({
            variables: {
                input: {
                    userEntityId,
                    tournamentEntityId: tournament.entityId,
                    organizationEntityId: data.organizationEntityId || null,
                    categoryEntityId: data.category.filter(Boolean).map(Number),
                    officialTitleEntityId: data.officialTitleEntityId || null,
                    judgeTitleEntityId: data.judgeTitleEntityId || null,
                    weight: data.weight || null,
                    height: data.height || null,
                    additionalInformation: data.additionalInformation || null,
                },
            },
            onCompleted: () => onRegistrationComplete(userEntityId),
            onError: error =>
                show({
                    type: 'error',
                    body: <pre>{JSON.stringify(error, null, 2)}</pre>,
                }),
        });
    };

    if (!user) {
        return (
            <Alert severity="warning">
                <AlertTitle>
                    <FormattedMessage
                        defaultMessage="User is not part of any organization owned by you"
                        id="3x4nKP"
                    />
                </AlertTitle>
                <FormattedMessage
                    defaultMessage="You cannot edit registration for this user. User is not part of any organization owned by you. Please contact the tournament organizer to do the change."
                    id="QLTIWt"
                />
            </Alert>
        );
    }

    const filteredCategories = tournament.categories.filter(
        filterUserCategories({
            user,
            tournament,
            isTeam: false,
        })
    );

    return (
        <Formik
            key={userEntityId}
            initialValues={initialValues}
            enableReinitialize
            onSubmit={onSubmit}
        >
            {({ values, setFieldValue }) => (
                <Form>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel>
                            {hasOrganizationTypeOrganization ? (
                                <FormattedMessage
                                    defaultMessage="School / Organization"
                                    id="Us/6ab"
                                />
                            ) : (
                                <FormattedMessage defaultMessage="School" id="c9n6Sh" />
                            )}
                        </InputLabel>
                        <Select
                            fullWidth
                            variant="filled"
                            value={values.organizationEntityId}
                            disabled={registrationIsClosed || userOrganizations.length === 1}
                            onChange={e =>
                                setFieldValue('organizationEntityId', Number(e.target.value))
                            }
                        >
                            {userOrganizations.map(organization => (
                                <MenuItem key={organization.entityId} value={organization.entityId}>
                                    {organization.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Box
                        display="grid"
                        gridTemplateColumns="1fr auto"
                        alignItems="center"
                        gap={2}
                        mb={2}
                    >
                        <FieldArray name="category">
                            {({ remove, push }) => (
                                <>
                                    {values.category.map((categoryValue, index) => (
                                        <Fragment key={index}>
                                            <FormikSelect
                                                name={`category.${index}`}
                                                selectProps={{
                                                    value: categoryValue,
                                                    disabled: !filteredCategories.length,
                                                }}
                                                label={
                                                    <FormattedMessage
                                                        defaultMessage="Category"
                                                        id="ccXLVi"
                                                    />
                                                }
                                                formControlProps={{
                                                    fullWidth: true,
                                                    disabled: registrationIsClosed,
                                                }}
                                            >
                                                {filteredCategories.map(category => (
                                                    <MenuItem
                                                        disabled={values.category.includes(
                                                            category.entityId
                                                        )}
                                                        key={category.entityId}
                                                        value={category.entityId}
                                                    >
                                                        {category.name}
                                                    </MenuItem>
                                                ))}
                                            </FormikSelect>
                                            <div>
                                                <IconButton
                                                    color="error"
                                                    disabled={
                                                        !categoryValue || registrationIsClosed
                                                    }
                                                    onClick={() => {
                                                        if (values.category.length === 1) {
                                                            setFieldValue('category.0', '');
                                                        } else {
                                                            remove(index);
                                                        }
                                                    }}
                                                >
                                                    <Clear />
                                                </IconButton>

                                                {index === values.category.length - 1 && (
                                                    <IconButton
                                                        color="success"
                                                        disabled={
                                                            !categoryValue || registrationIsClosed
                                                        }
                                                        onClick={() => push('')}
                                                        sx={{ ml: 1 }}
                                                    >
                                                        <Add />
                                                    </IconButton>
                                                )}
                                            </div>
                                            {!filteredCategories.length && (
                                                <Box
                                                    sx={{
                                                        gridColumnStart: 1,
                                                        gridColumnEnd: 3,
                                                    }}
                                                >
                                                    <Alert severity="warning">
                                                        <AlertTitle>
                                                            <FormattedMessage
                                                                defaultMessage="No categories available for this user"
                                                                id="Ydk/PM"
                                                            />
                                                        </AlertTitle>
                                                        <FormattedMessage
                                                            defaultMessage="Please check user's age, grade and other information required for registration, otherwise contact the organizer."
                                                            id="KK6L3z"
                                                        />
                                                    </Alert>
                                                </Box>
                                            )}
                                        </Fragment>
                                    ))}
                                </>
                            )}
                        </FieldArray>

                        {!!values.category.filter(Boolean).length &&
                            [
                                isWeightRequired,
                                isHeightRequired,
                                isAdditionalInformationRequired,
                            ].some(Boolean) && (
                                <Stack
                                    gap={2}
                                    sx={{
                                        gridColumnStart: 1,
                                        gridColumnEnd: 3,
                                    }}
                                >
                                    <TournamentRegistrationAdditionalInformationFields
                                        tournamentRegistrationSetuo={
                                            tournament.registration || null
                                        }
                                    />
                                </Stack>
                            )}

                        {hasRegistrationOfficial && (
                            <>
                                <FormikSelect
                                    name="officialTitleEntityId"
                                    selectProps={{
                                        value: values.officialTitleEntityId,
                                    }}
                                    label={
                                        <FormattedMessage defaultMessage="Official" id="cGPdyw" />
                                    }
                                    formControlProps={{
                                        fullWidth: true,
                                        disabled: registrationIsClosed,
                                    }}
                                >
                                    {tournament.registration?.officialTitles.map(title => (
                                        <MenuItem key={title.entityId} value={title.entityId}>
                                            {title.name}
                                        </MenuItem>
                                    ))}
                                </FormikSelect>
                                <div>
                                    <IconButton
                                        color="error"
                                        disabled={
                                            !values.officialTitleEntityId || registrationIsClosed
                                        }
                                        onClick={() => setFieldValue('officialTitleEntityId', '')}
                                    >
                                        <Clear />
                                    </IconButton>
                                </div>
                            </>
                        )}

                        {hasRegistrationJudge && (
                            <>
                                <FormikSelect
                                    name="judgeTitleEntityId"
                                    label={<FormattedMessage defaultMessage="Judge" id="Shrpls" />}
                                    selectProps={{ value: values.judgeTitleEntityId }}
                                    formControlProps={{
                                        fullWidth: true,
                                        disabled: registrationIsClosed,
                                    }}
                                >
                                    {tournament.registration?.judgeTitles.map(title => (
                                        <MenuItem key={title.entityId} value={title.entityId}>
                                            {title.name}
                                        </MenuItem>
                                    ))}
                                </FormikSelect>
                                <div>
                                    <IconButton
                                        color="error"
                                        disabled={
                                            !values.judgeTitleEntityId || registrationIsClosed
                                        }
                                        onClick={() => setFieldValue('judgeTitleEntityId', '')}
                                    >
                                        <Clear />
                                    </IconButton>
                                </div>
                            </>
                        )}
                    </Box>
                    <LoadingButton
                        isLoading={inFlight}
                        type="submit"
                        fullWidth
                        disabled={
                            registrationIsClosed ||
                            (!hasBeenRegistered &&
                                !values.category.filter(Boolean).length &&
                                !values.judgeTitleEntityId &&
                                !values.officialTitleEntityId)
                        }
                    >
                        <FormattedMessage defaultMessage="Confirm" id="N2IrpM" />
                    </LoadingButton>
                </Form>
            )}
        </Formik>
    );
};

export default TournamentRegistrationUserForm;
