/**
 * @generated SignedSource<<f5a0018c3e66bf5b522c5113852a9ad5>>
 * @relayHash 3ed582bc497c9158247138ba19cec792
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 3ed582bc497c9158247138ba19cec792

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type IntComparisonExp = {
  _eq?: number | null | undefined;
  _in?: ReadonlyArray<number> | null | undefined;
  _neq?: number | null | undefined;
  _nin?: ReadonlyArray<number> | null | undefined;
};
export type useUserSearchQueryQuery$variables = {
  after?: string | null | undefined;
  entityId?: IntComparisonExp | null | undefined;
  first?: number | null | undefined;
  organizationEntityId?: ReadonlyArray<number> | null | undefined;
  search?: string | null | undefined;
  shouldFetch: boolean;
  userEntityId?: number | null | undefined;
};
export type useUserSearchQueryQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useUserSearchQuery_query">;
};
export type useUserSearchQueryQuery = {
  response: useUserSearchQueryQuery$data;
  variables: useUserSearchQueryQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "entityId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationEntityId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "shouldFetch"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userEntityId"
},
v7 = [
  {
    "kind": "Variable",
    "name": "_like",
    "variableName": "search"
  }
],
v8 = {
  "kind": "Variable",
  "name": "parentOrganizationEntityId",
  "variableName": "organizationEntityId"
},
v9 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "fields": [
      {
        "items": [
          {
            "fields": [
              {
                "fields": (v7/*: any*/),
                "kind": "ObjectValue",
                "name": "firstName"
              }
            ],
            "kind": "ObjectValue",
            "name": "_or.0"
          },
          {
            "fields": [
              {
                "fields": (v7/*: any*/),
                "kind": "ObjectValue",
                "name": "lastName"
              }
            ],
            "kind": "ObjectValue",
            "name": "_or.1"
          }
        ],
        "kind": "ListValue",
        "name": "_or"
      },
      {
        "kind": "Variable",
        "name": "entityId",
        "variableName": "entityId"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "organizationEntityId",
    "variableName": "organizationEntityId"
  },
  (v8/*: any*/),
  {
    "kind": "Variable",
    "name": "userEntityId",
    "variableName": "userEntityId"
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useUserSearchQueryQuery",
    "selections": [
      {
        "condition": "shouldFetch",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "useUserSearchQuery_query"
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v6/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Operation",
    "name": "useUserSearchQueryQuery",
    "selections": [
      {
        "condition": "shouldFetch",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v9/*: any*/),
            "concreteType": "UserConnection",
            "kind": "LinkedField",
            "name": "users",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalResults",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "firstName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "fullName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "birthDay",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "age",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "photo",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserData",
                        "kind": "LinkedField",
                        "name": "userData",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Grade",
                            "kind": "LinkedField",
                            "name": "grade",
                            "plural": false,
                            "selections": [
                              (v10/*: any*/),
                              (v11/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v12/*: any*/),
                      {
                        "alias": null,
                        "args": [
                          (v8/*: any*/)
                        ],
                        "concreteType": "Organization",
                        "kind": "LinkedField",
                        "name": "organizations",
                        "plural": true,
                        "selections": [
                          (v12/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v11/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v9/*: any*/),
            "filters": [
              "organizationEntityId",
              "parentOrganizationEntityId",
              "userEntityId",
              "filter"
            ],
            "handle": "connection",
            "key": "useUserSearchQuery_users",
            "kind": "LinkedHandle",
            "name": "users"
          }
        ]
      }
    ]
  },
  "params": {
    "id": "3ed582bc497c9158247138ba19cec792",
    "metadata": {},
    "name": "useUserSearchQueryQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "ee5979ccecf7271c840476b97f638135";

export default node;
