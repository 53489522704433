import { FC } from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

type Props = ButtonProps & {
    isLoading: boolean;
};

const LoadingButton: FC<Props> = ({ children, isLoading, disabled, ...props }) => {
    return (
        <Button {...props} disabled={disabled || isLoading}>
            {children}
            {isLoading && (
                <CircularProgress
                    size={16}
                    color="inherit"
                    sx={{
                        ml: 2,
                        verticalAlign: 'middle',
                    }}
                />
            )}
        </Button>
    );
};

export default LoadingButton;
