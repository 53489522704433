import { FC, Suspense, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useLazyLoadQuery } from 'react-relay';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { UserInfoCardLoader } from '@ktech/components/src/user/UserInfoCard';
import UserSearchSelect from '@/components/user/UserSearchSelect';

import TournamentRegistrationSuccess from './TournamentRegistrationSuccess';
import TournamentRegistrationUserForm from './TournamentRegistrationUserForm';
import { TournamentRegistrationDialogQuery } from './__generated__/TournamentRegistrationDialogQuery.graphql';

type Props = {
    tournamentEntityId: number;
    organizationEntityId?: number | null;
    ownerUserEntityId?: number | null;
    userEntityId?: number[];
    open: boolean;
    onClose: (registrationComplete: boolean) => void;
    onRegistrationComplete?: () => void;
};

const TournamentRegistrationDialog: FC<Props> = ({
    open,
    onClose,
    onRegistrationComplete,
    tournamentEntityId,
    organizationEntityId,
    ownerUserEntityId,
    userEntityId = [],
}) => {
    const [activeUserIndex, setActiveUserIndex] = useState(0);
    const [registeredUserEntityIds, setRegisteredUserEntityIds] = useState<number[]>([]);
    const [showSuccess, setShowSuccess] = useState(false);
    const [selectedUserEntityId, setSelectedUserEntityId] = useState<number | null>(null);
    const activeUserEntityId = userEntityId[activeUserIndex] || null;

    const query = useLazyLoadQuery<TournamentRegistrationDialogQuery>(
        graphql`
            query TournamentRegistrationDialogQuery(
                $tournamentEntityId: Int! # $ownerUserEntityId: Int # $fetchOrganizations: Boolean!
            ) {
                tournament(entityId: $tournamentEntityId) {
                    ...TournamentRegistrationUserForm_tournament
                }
            }
        `,
        {
            tournamentEntityId,
        },
        { fetchPolicy: 'store-and-network' }
    );

    useEffect(() => {
        setSelectedUserEntityId(null);
        setShowSuccess(false);
    }, [open]);

    if (!query.tournament) {
        return null;
    }

    const isLastActiveUser =
        activeUserIndex === userEntityId.length - 1 ||
        (!userEntityId.length && !!selectedUserEntityId);
    const registeredUserEntityId = activeUserEntityId || selectedUserEntityId;

    return (
        <Dialog open={open} onClose={() => onClose(showSuccess)} fullWidth maxWidth="sm">
            <DialogContent>
                {showSuccess ? (
                    <Suspense>
                        <TournamentRegistrationSuccess
                            tournamentEntityId={tournamentEntityId}
                            userEntityIds={registeredUserEntityIds}
                        />
                    </Suspense>
                ) : (
                    <>
                        {userEntityId.length > 1 && (
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                mb={4}
                            >
                                <Button
                                    disabled={!activeUserIndex}
                                    onClick={() => setActiveUserIndex(activeUserIndex - 1)}
                                    variant="outlined"
                                    size="large"
                                >
                                    <ChevronLeft />
                                </Button>
                                <Box>
                                    <FormattedMessage
                                        defaultMessage="Registration {current} of {total}"
                                        id="MI/AaB"
                                        values={{
                                            current: activeUserIndex + 1,
                                            total: userEntityId.length,
                                        }}
                                    />
                                </Box>
                                <Button
                                    disabled={isLastActiveUser}
                                    onClick={() => setActiveUserIndex(activeUserIndex + 1)}
                                    variant="outlined"
                                    size="large"
                                >
                                    <ChevronRight />
                                </Button>
                            </Box>
                        )}
                        {!userEntityId.length && !selectedUserEntityId && (
                            <UserSearchSelect
                                organizationEntityId={organizationEntityId}
                                ownerUserEntityId={ownerUserEntityId}
                                onSelect={setSelectedUserEntityId}
                            />
                        )}
                        <Suspense>
                            {registeredUserEntityId && (
                                <>
                                    <UserInfoCardLoader
                                        userEntityId={registeredUserEntityId}
                                        sx={{ mb: 2 }}
                                    />
                                    <TournamentRegistrationUserForm
                                        userEntityId={registeredUserEntityId}
                                        parentOrganizationEntityId={organizationEntityId}
                                        tournament={query.tournament}
                                        onRegistrationComplete={userEntityId => {
                                            setRegisteredUserEntityIds([
                                                ...registeredUserEntityIds,
                                                userEntityId,
                                            ]);

                                            if (isLastActiveUser) {
                                                setShowSuccess(true);
                                                onRegistrationComplete?.();
                                            } else {
                                                setActiveUserIndex(activeUserIndex + 1);
                                            }
                                        }}
                                    />
                                </>
                            )}
                        </Suspense>
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default TournamentRegistrationDialog;
