/**
 * @generated SignedSource<<4e7daeb0922c104dc7682bb4dbc0bbc8>>
 * @relayHash 7dece11db1e2390a522b03c9613bf567
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 7dece11db1e2390a522b03c9613bf567

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TournamentRegistrationRegisterUserInput = {
  additionalInformation?: string | null | undefined;
  categoryEntityId?: ReadonlyArray<number> | null | undefined;
  height?: number | null | undefined;
  judgeTitleEntityId?: number | null | undefined;
  officialTitleEntityId?: number | null | undefined;
  organizationEntityId?: number | null | undefined;
  tournamentEntityId: number;
  userEntityId: number;
  weight?: number | null | undefined;
};
export type TournamentRegistrationUserFormMutation$variables = {
  input: TournamentRegistrationRegisterUserInput;
};
export type TournamentRegistrationUserFormMutation$data = {
  readonly tournamentRegistrationRegisterUser: {
    readonly deletedNodeId: string | null | undefined;
    readonly tournamentRegistrationUser: {
      readonly " $fragmentSpreads": FragmentRefs<"TournamentRegistrationUserForm_tournamentRegistration">;
    } | null | undefined;
  };
};
export type TournamentRegistrationUserFormMutation = {
  response: TournamentRegistrationUserFormMutation$data;
  variables: TournamentRegistrationUserFormMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedNodeId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TournamentRegistrationUserFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TournamentRegistrationRegisterUserResponse",
        "kind": "LinkedField",
        "name": "tournamentRegistrationRegisterUser",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TournamentRegistrationUser",
            "kind": "LinkedField",
            "name": "tournamentRegistrationUser",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "TournamentRegistrationUserForm_tournamentRegistration"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TournamentRegistrationUserFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TournamentRegistrationRegisterUserResponse",
        "kind": "LinkedField",
        "name": "tournamentRegistrationRegisterUser",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "deleteRecord",
            "key": "",
            "kind": "ScalarHandle",
            "name": "deletedNodeId"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TournamentRegistrationUser",
            "kind": "LinkedField",
            "name": "tournamentRegistrationUser",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "additionalInformation",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserData",
                "kind": "LinkedField",
                "name": "userData",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "weight",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "height",
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TournamentRegistrationParticipant",
                "kind": "LinkedField",
                "name": "participants",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Category",
                    "kind": "LinkedField",
                    "name": "category",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TournamentRegistrationJudge",
                "kind": "LinkedField",
                "name": "judges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "judgeTitleEntityId",
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TournamentRegistrationOfficial",
                "kind": "LinkedField",
                "name": "officials",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "officialTitleEntityId",
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "7dece11db1e2390a522b03c9613bf567",
    "metadata": {},
    "name": "TournamentRegistrationUserFormMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "4c60db7597fc33ceb1e2f26d6b921c83";

export default node;
