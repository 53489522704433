/**
 * @generated SignedSource<<70e24774164c7a219604b89a98d73b72>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TournamentRegistrationUserInfo_tournamentRegistration$data = {
  readonly judges: ReadonlyArray<{
    readonly title: string;
  } | null | undefined>;
  readonly officials: ReadonlyArray<{
    readonly title: string;
  } | null | undefined>;
  readonly participants: ReadonlyArray<{
    readonly category: {
      readonly name: string;
    };
  } | null | undefined>;
  readonly teams: ReadonlyArray<{
    readonly entityId: number;
    readonly participants: ReadonlyArray<{
      readonly category: {
        readonly name: string;
      };
      readonly entityId: number;
    } | null | undefined>;
  } | null | undefined>;
  readonly " $fragmentType": "TournamentRegistrationUserInfo_tournamentRegistration";
};
export type TournamentRegistrationUserInfo_tournamentRegistration$key = {
  readonly " $data"?: TournamentRegistrationUserInfo_tournamentRegistration$data;
  readonly " $fragmentSpreads": FragmentRefs<"TournamentRegistrationUserInfo_tournamentRegistration">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "Category",
  "kind": "LinkedField",
  "name": "category",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "title",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TournamentRegistrationUserInfo_tournamentRegistration",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TournamentRegistrationParticipant",
      "kind": "LinkedField",
      "name": "participants",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TournamentRegistrationTeam",
      "kind": "LinkedField",
      "name": "teams",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "TournamentRegistrationParticipant",
          "kind": "LinkedField",
          "name": "participants",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TournamentRegistrationJudge",
      "kind": "LinkedField",
      "name": "judges",
      "plural": true,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TournamentRegistrationOfficial",
      "kind": "LinkedField",
      "name": "officials",
      "plural": true,
      "selections": (v2/*: any*/),
      "storageKey": null
    }
  ],
  "type": "TournamentRegistrationUser",
  "abstractKey": null
};
})();

(node as any).hash = "43c3fa91647f712f9f550fdbc2b9521b";

export default node;
