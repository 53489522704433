import {
    Card,
    CardProps,
    CardContent,
    CardHeader,
    CardHeaderProps,
    Typography,
} from '@mui/material';
import { FC, ReactNode } from 'react';
import { graphql, useFragment, useLazyLoadQuery } from 'react-relay';
import UserAvatar from './UserAvatar';
import { UserInfoCardLoaderQuery } from './__generated__/UserInfoCardLoaderQuery.graphql';
import { UserInfoCard_user$key } from './__generated__/UserInfoCard_user.graphql';

export type Props = {
    startNumber?: ReactNode;
    user: UserInfoCard_user$key;
    children?: ReactNode;
    cardHeaderProps?: CardHeaderProps;
} & CardProps;

const UserInfoCard: FC<Props> = ({
    startNumber,
    children,
    user: userRef,
    cardHeaderProps,
    ...cardProps
}) => {
    const user = useFragment(
        graphql`
            fragment UserInfoCard_user on User
            @argumentDefinitions(skipAge: { type: "Boolean", defaultValue: false }) {
                firstName
                lastName
                fullName
                birthDay @skip(if: $skipAge)
                age @skip(if: $skipAge)
                photo
                userData {
                    grade {
                        name
                    }
                }
            }
        `,
        userRef
    );

    return (
        <Card {...cardProps}>
            <CardHeader
                avatar={
                    <>
                        {startNumber}
                        <UserAvatar user={user} />
                    </>
                }
                title={user.fullName}
                subheader={
                    <>
                        {(user.birthDay || user.age) && (
                            <Typography variant="body2">{user.birthDay || user.age}</Typography>
                        )}
                        {user.userData.grade && (
                            <Typography variant="caption">{user.userData.grade.name}</Typography>
                        )}
                    </>
                }
                {...cardHeaderProps}
            />
            {children && <CardContent>{children}</CardContent>}
        </Card>
    );
};

export const UserInfoCardLoader: FC<Omit<Props, 'user'> & { userEntityId: number }> = ({
    userEntityId,
    children,
    ...props
}) => {
    const query = useLazyLoadQuery<UserInfoCardLoaderQuery>(
        graphql`
            query UserInfoCardLoaderQuery($userEntityId: Int!) {
                user(entityId: $userEntityId) {
                    ...UserInfoCard_user
                }
            }
        `,
        { userEntityId },
        { fetchPolicy: 'store-and-network' }
    );

    if (!query.user) {
        return null;
    }

    return (
        <UserInfoCard user={query.user} {...props}>
            {children}
        </UserInfoCard>
    );
};

export default UserInfoCard;
