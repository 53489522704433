/**
 * @generated SignedSource<<662a21eba96eb5378ec5a37589bf8b6e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TournamentRegistrationAdditionalInformationFields_tournamentRegistrationSetup$data = {
  readonly additionalInformationLabel: string | null | undefined;
  readonly isAdditionalInformationRequired: boolean;
  readonly isHeightRequired: boolean;
  readonly isWeightRequired: boolean;
  readonly " $fragmentType": "TournamentRegistrationAdditionalInformationFields_tournamentRegistrationSetup";
};
export type TournamentRegistrationAdditionalInformationFields_tournamentRegistrationSetup$key = {
  readonly " $data"?: TournamentRegistrationAdditionalInformationFields_tournamentRegistrationSetup$data;
  readonly " $fragmentSpreads": FragmentRefs<"TournamentRegistrationAdditionalInformationFields_tournamentRegistrationSetup">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TournamentRegistrationAdditionalInformationFields_tournamentRegistrationSetup",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isWeightRequired",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isHeightRequired",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isAdditionalInformationRequired",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "additionalInformationLabel",
      "storageKey": null
    }
  ],
  "type": "TournamentRegistrationSetup",
  "abstractKey": null
};

(node as any).hash = "826dfa8184da692f42490f909a54af68";

export default node;
