import { graphql, readInlineData } from 'react-relay';
import dayjs from 'dayjs';

import { filterUserCategories_user$key } from '../helper/__generated__/filterUserCategories_user.graphql';
import { filterUserCategories_category$key } from '../helper/__generated__/filterUserCategories_category.graphql';
import { filterUserCategories_tournament$key } from '../helper/__generated__/filterUserCategories_tournament.graphql';

const filterUserCategories = (payload: {
    user: filterUserCategories_user$key | null;
    isTeam: boolean;
    tournament: filterUserCategories_tournament$key;
    // skip age filter
    skipAgeFilter?: boolean;
    // skip grade filter
    skipGradeFilter?: boolean;
    // show selected category even if it was filtered out
    selectedCategoryEntityId?: number | null;
}) => {
    const user = readInlineData(
        graphql`
            fragment filterUserCategories_user on User @inline {
                birthDay
                age
                sex
                userData {
                    grade {
                        name
                    }
                }
            }
        `,
        payload.user
    );

    const tournament = readInlineData(
        graphql`
            fragment filterUserCategories_tournament on Tournament @inline {
                date
            }
        `,
        payload.tournament
    );

    return (categoryRef: filterUserCategories_category$key): boolean => {
        const { entityId, ageFrom, ageTo, checkTournamentDate, sex, isTeam, grades } =
            readInlineData(
                graphql`
                    fragment filterUserCategories_category on Category @inline {
                        entityId
                        checkTournamentDate
                        ageFrom
                        ageTo
                        sex
                        isTeam
                        grades {
                            grade {
                                name
                            }
                        }
                    }
                `,
                categoryRef
            );

        if (payload.isTeam) {
            return isTeam;
        }

        if (!user) {
            return true;
        }

        if (payload.selectedCategoryEntityId === entityId) {
            return true;
        }

        if (isTeam) {
            return false;
        }

        const isAgeFrom = dayjs(tournament.date)
            .subtract(ageFrom, 'year')
            .isAfter(user.birthDay, checkTournamentDate ? 'day' : 'year');

        let isAgeBefore = true;

        if (ageTo) {
            const tournamentYearTo = dayjs(tournament.date).subtract(ageTo, 'year');
            isAgeBefore =
                tournamentYearTo.isBefore(user.birthDay, checkTournamentDate ? 'day' : 'year') ||
                tournamentYearTo.isSame(user.birthDay, checkTournamentDate ? 'day' : 'year');
        }

        const categoryGradeNames = grades.map(({ grade }) => grade.name);
        const userGradeName = user.userData?.grade?.name ?? '';

        return (
            (sex === 'u' || sex === user.sex) &&
            ((isAgeFrom && isAgeBefore) || !!payload.skipAgeFilter) &&
            (!!payload.skipGradeFilter ||
                !userGradeName ||
                !categoryGradeNames.length ||
                categoryGradeNames.includes(userGradeName))
        );
    };
};

export default filterUserCategories;
