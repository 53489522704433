/**
 * @generated SignedSource<<3070ec0241d082a56c19abca7adc7ce9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserInfoCard_user$data = {
  readonly age?: number | null | undefined;
  readonly birthDay?: string | null | undefined;
  readonly firstName: string;
  readonly fullName: string;
  readonly lastName: string;
  readonly photo: string | null | undefined;
  readonly userData: {
    readonly grade: {
      readonly name: string;
    } | null | undefined;
  };
  readonly " $fragmentType": "UserInfoCard_user";
};
export type UserInfoCard_user$key = {
  readonly " $data"?: UserInfoCard_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserInfoCard_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "skipAge"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserInfoCard_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    {
      "condition": "skipAge",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "birthDay",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "age",
          "storageKey": null
        }
      ]
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "photo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserData",
      "kind": "LinkedField",
      "name": "userData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Grade",
          "kind": "LinkedField",
          "name": "grade",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "73603e4b879adb38c546935c7cf8072d";

export default node;
