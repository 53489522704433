import { filterFalsy } from '@ktech/relay';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { TournamentRegistrationUserInfo_tournamentRegistration$key } from './__generated__/TournamentRegistrationUserInfo_tournamentRegistration.graphql';

type Props = {
    tournamentRegistration: TournamentRegistrationUserInfo_tournamentRegistration$key | null;
};

const TournamentRegistrationUserInfo: FC<Props> = ({
    tournamentRegistration: tournamentRegistrationRef,
}) => {
    const tournamentRegistration = useFragment(
        graphql`
            fragment TournamentRegistrationUserInfo_tournamentRegistration on TournamentRegistrationUser {
                participants {
                    category {
                        name
                    }
                }
                teams {
                    entityId
                    participants {
                        entityId
                        category {
                            name
                        }
                    }
                }
                judges {
                    title
                }
                officials {
                    title
                }
            }
        `,
        tournamentRegistrationRef
    );

    if (!tournamentRegistration) {
        return null;
    }

    return (
        <>
            {(!!tournamentRegistration.participants.length ||
                !!tournamentRegistration.teams.length) && (
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant="subtitle1">
                        <FormattedMessage defaultMessage="Categories:" id="+hjQcv" />
                    </Typography>
                    {tournamentRegistration.participants.filter(filterFalsy).map(participant => (
                        <Chip key={participant.category.name} label={participant.category.name} />
                    ))}
                    {tournamentRegistration.teams
                        ?.filter(filterFalsy)
                        .map(team =>
                            team.participants
                                .filter(filterFalsy)
                                .map(participant => (
                                    <Chip
                                        key={participant.entityId}
                                        label={participant.category.name}
                                    />
                                ))
                        )}
                </Stack>
            )}

            {(!!tournamentRegistration.judges.length ||
                !!tournamentRegistration.officials.length) && (
                <Stack direction="row" spacing={1} alignItems="center" mt={2}>
                    <Typography variant="subtitle1">
                        <FormattedMessage defaultMessage="Other Registrations:" id="DTtoZm" />
                    </Typography>
                    {[...tournamentRegistration.judges, ...tournamentRegistration.officials]
                        .filter(filterFalsy)
                        .map(registration => (
                            <Chip key={registration.title} label={registration.title} />
                        ))}
                </Stack>
            )}
        </>
    );
};

export default TournamentRegistrationUserInfo;
