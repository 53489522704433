import { useEffect, useState } from 'react';
import { graphql, useLazyLoadQuery, usePaginationFragment } from 'react-relay';
import { useUserSearchQueryQuery } from './__generated__/useUserSearchQueryQuery.graphql';
import { useUserSearchQueryRefetchQuery } from './__generated__/useUserSearchQueryRefetchQuery.graphql';
import { useUserSearchQuery_query$key } from './__generated__/useUserSearchQuery_query.graphql';

const LOAD_LIMIT = 20;

type UseUserSearchQueryPayload = {
    search?: string | null;
    entityIds?: number[] | null;
    shouldFetch?: boolean;
    organizationEntityId?: number[] | number | null;
    ownerUserEntityId?: number | null;
};

export const useUserSearchQuery = ({
    search,
    entityIds,
    organizationEntityId,
    ownerUserEntityId,
    shouldFetch: _shouldFetch = true,
}: UseUserSearchQueryPayload) => {
    const [shouldFetch, setShouldFetch] = useState(_shouldFetch);

    useEffect(() => {
        if (_shouldFetch) {
            setShouldFetch(true);
        }
    }, [_shouldFetch]);

    const query = useLazyLoadQuery<useUserSearchQueryQuery>(
        graphql`
            query useUserSearchQueryQuery(
                $organizationEntityId: [Int!]
                $userEntityId: Int
                $search: String
                $entityId: IntComparisonExp
                $first: Int
                $after: String
                $shouldFetch: Boolean!
            ) {
                ...useUserSearchQuery_query @include(if: $shouldFetch)
            }
        `,
        {
            search: search ?? '',
            entityId: entityIds?.length ? { _in: entityIds } : null,
            organizationEntityId: Array.isArray(organizationEntityId)
                ? organizationEntityId
                : organizationEntityId
                ? [organizationEntityId]
                : null,

            userEntityId: ownerUserEntityId,
            first: search ? LOAD_LIMIT : 6,
            shouldFetch,
        },
        { fetchPolicy: 'network-only' }
    );

    const { data, refetch, loadNext, hasNext, isLoadingNext } = usePaginationFragment<
        useUserSearchQueryRefetchQuery,
        useUserSearchQuery_query$key
    >(
        graphql`
            fragment useUserSearchQuery_query on Query
            @refetchable(queryName: "useUserSearchQueryRefetchQuery") {
                users(
                    organizationEntityId: $organizationEntityId
                    parentOrganizationEntityId: $organizationEntityId
                    userEntityId: $userEntityId
                    filter: {
                        entityId: $entityId
                        _or: [{ firstName: { _like: $search } }, { lastName: { _like: $search } }]
                    }
                    first: $first
                    after: $after
                ) @connection(key: "useUserSearchQuery_users") {
                    totalResults
                    edges {
                        cursor
                        node {
                            ...UserInfoCard_user
                            entityId
                            fullName
                            organizations(parentOrganizationEntityId: $organizationEntityId) {
                                entityId
                                name
                            }
                        }
                    }
                }
            }
        `,
        shouldFetch ? query : null
    );

    return {
        data,
        loadNext,
        refetch,
        hasNext,
        isLoadingNext,
    };
};
