import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import Alert from '@mui/material/Alert';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { TournamentRegistrationSuccessQuery } from './__generated__/TournamentRegistrationSuccessQuery.graphql';
import UserInfoCard from '@ktech/components/src/user/UserInfoCard';
import TournamentRegistrationUserInfo from './TournamentRegistrationUserInfo';
import { filterNullNodes } from '@ktech/relay';

type Props = {
    userEntityIds: number[];
    tournamentEntityId: number;
};

const TournamentRegistrationSuccess: FC<Props> = ({ userEntityIds, tournamentEntityId }) => {
    const query = useLazyLoadQuery<TournamentRegistrationSuccessQuery>(
        graphql`
            query TournamentRegistrationSuccessQuery(
                $userEntityIds: [Int!]!
                $tournamentEntityId: Int!
            ) {
                users(filter: { entityId: { _in: $userEntityIds } }) {
                    edges {
                        cursor
                        node {
                            ...UserInfoCard_user
                            tournamentRegistration(tournamentEntityId: $tournamentEntityId) {
                                ...TournamentRegistrationUserInfo_tournamentRegistration
                            }
                        }
                    }
                }
            }
        `,
        {
            userEntityIds,
            tournamentEntityId,
        },
        { fetchPolicy: 'store-and-network' }
    );

    return (
        <>
            <Alert severity="success">
                <FormattedMessage defaultMessage="Registration completed!" id="7HYkIA" />
            </Alert>

            {query.users.edges.filter(filterNullNodes).map(user => (
                <UserInfoCard key={user.cursor} user={user.node} style={{ marginTop: 16 }}>
                    <TournamentRegistrationUserInfo
                        tournamentRegistration={user.node.tournamentRegistration || null}
                    />
                </UserInfoCard>
            ))}
            {/* <pre>{JSON.stringify(query.users, null, 2)}</pre> */}
        </>
    );
};

export default TournamentRegistrationSuccess;
