/**
 * @generated SignedSource<<68bb87409705a75575feafe7ccaf07a6>>
 * @relayHash 56bbeba710fa75cbbbffc37fe7750423
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 56bbeba710fa75cbbbffc37fe7750423

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type IntComparisonExp = {
  _eq?: number | null | undefined;
  _in?: ReadonlyArray<number> | null | undefined;
  _neq?: number | null | undefined;
  _nin?: ReadonlyArray<number> | null | undefined;
};
export type useUserSearchQueryRefetchQuery$variables = {
  after?: string | null | undefined;
  entityId?: IntComparisonExp | null | undefined;
  first?: number | null | undefined;
  organizationEntityId?: ReadonlyArray<number> | null | undefined;
  search?: string | null | undefined;
  userEntityId?: number | null | undefined;
};
export type useUserSearchQueryRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useUserSearchQuery_query">;
};
export type useUserSearchQueryRefetchQuery = {
  response: useUserSearchQueryRefetchQuery$data;
  variables: useUserSearchQueryRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "entityId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationEntityId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userEntityId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "_like",
    "variableName": "search"
  }
],
v2 = {
  "kind": "Variable",
  "name": "parentOrganizationEntityId",
  "variableName": "organizationEntityId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "fields": [
      {
        "items": [
          {
            "fields": [
              {
                "fields": (v1/*: any*/),
                "kind": "ObjectValue",
                "name": "firstName"
              }
            ],
            "kind": "ObjectValue",
            "name": "_or.0"
          },
          {
            "fields": [
              {
                "fields": (v1/*: any*/),
                "kind": "ObjectValue",
                "name": "lastName"
              }
            ],
            "kind": "ObjectValue",
            "name": "_or.1"
          }
        ],
        "kind": "ListValue",
        "name": "_or"
      },
      {
        "kind": "Variable",
        "name": "entityId",
        "variableName": "entityId"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "organizationEntityId",
    "variableName": "organizationEntityId"
  },
  (v2/*: any*/),
  {
    "kind": "Variable",
    "name": "userEntityId",
    "variableName": "userEntityId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUserSearchQueryRefetchQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "useUserSearchQuery_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUserSearchQueryRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "UserConnection",
        "kind": "LinkedField",
        "name": "users",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalResults",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fullName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "birthDay",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "age",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "photo",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserData",
                    "kind": "LinkedField",
                    "name": "userData",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Grade",
                        "kind": "LinkedField",
                        "name": "grade",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": [
                      (v2/*: any*/)
                    ],
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organizations",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "filters": [
          "organizationEntityId",
          "parentOrganizationEntityId",
          "userEntityId",
          "filter"
        ],
        "handle": "connection",
        "key": "useUserSearchQuery_users",
        "kind": "LinkedHandle",
        "name": "users"
      }
    ]
  },
  "params": {
    "id": "56bbeba710fa75cbbbffc37fe7750423",
    "metadata": {},
    "name": "useUserSearchQueryRefetchQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "2906b1cd4a1e29c3eb03635614941289";

export default node;
