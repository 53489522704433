/**
 * @generated SignedSource<<a651c542782e553c94176bb478887ba3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TournamentRegistrationUserForm_tournamentRegistration$data = {
  readonly additionalInformation: string | null | undefined;
  readonly entityId: number;
  readonly judges: ReadonlyArray<{
    readonly judgeTitleEntityId: number;
    readonly title: string;
  } | null | undefined>;
  readonly officials: ReadonlyArray<{
    readonly officialTitleEntityId: number;
    readonly title: string;
  } | null | undefined>;
  readonly participants: ReadonlyArray<{
    readonly category: {
      readonly entityId: number;
    };
  } | null | undefined>;
  readonly userData: {
    readonly height: number | null | undefined;
    readonly weight: number | null | undefined;
  };
  readonly " $fragmentType": "TournamentRegistrationUserForm_tournamentRegistration";
};
export type TournamentRegistrationUserForm_tournamentRegistration$key = {
  readonly " $data"?: TournamentRegistrationUserForm_tournamentRegistration$data;
  readonly " $fragmentSpreads": FragmentRefs<"TournamentRegistrationUserForm_tournamentRegistration">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TournamentRegistrationUserForm_tournamentRegistration",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "additionalInformation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserData",
      "kind": "LinkedField",
      "name": "userData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "weight",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "height",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TournamentRegistrationParticipant",
      "kind": "LinkedField",
      "name": "participants",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Category",
          "kind": "LinkedField",
          "name": "category",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TournamentRegistrationJudge",
      "kind": "LinkedField",
      "name": "judges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "judgeTitleEntityId",
          "storageKey": null
        },
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TournamentRegistrationOfficial",
      "kind": "LinkedField",
      "name": "officials",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "officialTitleEntityId",
          "storageKey": null
        },
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "TournamentRegistrationUser",
  "abstractKey": null
};
})();

(node as any).hash = "3e8ce2c59862553adab437a2799160ff";

export default node;
