/**
 * @generated SignedSource<<b21d353249cade3da15c3cfce6b9c5f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type SexEnum = "f" | "m" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type filterUserCategories_user$data = {
  readonly age: number | null | undefined;
  readonly birthDay: string | null | undefined;
  readonly sex: SexEnum | null | undefined;
  readonly userData: {
    readonly grade: {
      readonly name: string;
    } | null | undefined;
  };
  readonly " $fragmentType": "filterUserCategories_user";
};
export type filterUserCategories_user$key = {
  readonly " $data"?: filterUserCategories_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"filterUserCategories_user">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "filterUserCategories_user"
};

(node as any).hash = "5a8434397699913a15bf9cb875aa6952";

export default node;
