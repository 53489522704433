import { FC } from 'react';
import Avatar, { AvatarProps } from '@mui/material/Avatar';
import { getFilePath } from '../helper/fileHelper';

type Props = {
    width?: number;
    height?: number;
    user: {
        photo: string | null | undefined;
        firstName: string;
        lastName: string;
    };
};

const UserAvatar: FC<Props & AvatarProps> = ({
    user,
    width = 100,
    height = 100,
    ...avatarProps
}) => {
    return (
        <Avatar
            src={getFilePath(user.photo, {
                width,
                height,
            })}
            component="span"
            variant="rounded"
            {...avatarProps}
            slotProps={{
                img: { loading: 'lazy', sx: { objectFit: 'contain' } },
            }}
            sx={{ height: 'auto', aspectRatio: 0.8, ...avatarProps.sx }}
        >
            {user.firstName[0]}
            {user.lastName[0]}
        </Avatar>
    );
};

export default UserAvatar;
