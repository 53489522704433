/**
 * @generated SignedSource<<2d6a4c5c42fd6958181f32ffe49e15d9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type CategorySexEnum = "f" | "m" | "u" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type filterUserCategories_category$data = {
  readonly ageFrom: number;
  readonly ageTo: number;
  readonly checkTournamentDate: boolean;
  readonly entityId: number;
  readonly grades: ReadonlyArray<{
    readonly grade: {
      readonly name: string;
    };
  }>;
  readonly isTeam: boolean;
  readonly sex: CategorySexEnum;
  readonly " $fragmentType": "filterUserCategories_category";
};
export type filterUserCategories_category$key = {
  readonly " $data"?: filterUserCategories_category$data;
  readonly " $fragmentSpreads": FragmentRefs<"filterUserCategories_category">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "filterUserCategories_category"
};

(node as any).hash = "691eaa12c4faa2df848f60aec2c5c61a";

export default node;
